<template>
  <div>
    <el-row :gutter="20" class="item-row">
      <el-col :span="6">
        <div class="item_list">
          <div class="topBox">
            <div class="textBox">
              <span>{{ homeData.current_revenue ? homeData.current_revenue : 0 | unitPrice("") }}</span>
              <div>总营收(元)</div>
            </div>
            <img src="/img/dd-y@2x.png"/>
          </div>
          <div
            class="bottomBox"
            style="background-image: linear-gradient(to right, #34cdbd, #129b95);"
          >
            <span>本月订单总数</span>
            <span>{{ homeData.current_order_sum ? homeData.current_order_sum : 0 }}笔</span>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="item_list">
          <div class="topBox">
            <div class="textBox">
              <span>{{ homeData.current_refund_price ? homeData.current_refund_price : 0 | unitPrice("") }}</span>
              <div>总退款(元)</div>
            </div>
            <img src="/img/tk-y@2x.png"/>
          </div>
          <div
            class="bottomBox"
            style="background-image: linear-gradient(to right, #fe8058, #f94d4b);"
          >
            <span>本月退款</span>
            <span>{{ homeData.current_refund_sum ? homeData.current_refund_sum : 0 }}笔</span>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="item_list">
          <div class="topBox">
            <div class="textBox">
              <span>{{ homeData.revenue ? homeData.revenue : 0 | unitPrice("") }}</span>
              <div>总营收(元)</div>
            </div>
            <img src="/img/dd-z@2x.png"/>
          </div>
          <div
            class="bottomBox"
            style="background-image: linear-gradient(to right, #73abff, #2079ff);"
          >
            <span>累计订单总数</span>
            <span>{{ homeData.order_sum ? homeData.order_sum : 0 }}笔</span>
          </div>
        </div>
      </el-col>
      <el-col :span="6">
        <div class="item_list">
          <div class="topBox">
            <div class="textBox">
              <span>{{ homeData.refund_price ? homeData.refund_price : 0 | unitPrice("") }}</span>
              <div>总退款(元)</div>
            </div>
            <img src="/img/tk-z@2x.png"/>
          </div>
          <div
            class="bottomBox"
            style="background-image: linear-gradient(to right, #f3a433, #fe8d24);"
          >
            <span>累计退款</span>
            <span>{{ homeData.refund_sum ? homeData.refund_sum : 0 }}笔</span>
          </div>
        </div>
      </el-col>
    </el-row>
    <div class="echart-container">
      <div class="echart-title">
        <p>订单金额统计</p>
        <p>
          <span :class="active==='day'?'active':''" @click="handleTime(0)">今日</span>
          <span :class="active==='week'?'active':''" @click="handleTime(7)">近7天</span>
          <span :class="active==='mouth'?'active':''" @click="handleTime(30)">近30天</span>
          <span :class="active==='year'?'active':''" @click="handleTime(365)">近1年</span>
          <el-date-picker
            :default-time="['00:00:00', '23:59:59']"
            value-format="timestamp"
            style="width: 320px;margin-left: 10px;"
            v-model="advancedForm1.times"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="{disabledDate(time) { return time.getTime() > Date.now() }}"
            @change="timeChange"
          ></el-date-picker>
        </p>
      </div>
      <div class="echart-alltitle">
        <div class="echarts-left">
          <h2>{{ orderLeftList.order_sum ? orderLeftList.order_sum : 0 }}</h2>
          <p>{{ activeName }}订单总数(笔)</p>
        </div>
        <div :span="20" id="echarts_right" class="echarts-right"></div>
      </div>
    </div>

    <!-- shopMoney 概况 -->
    <en-table-layout tips :tableData="tableData.data" :loading="loading" :toolbar="false">
      <!-- 搜索条件 -->
      <div slot="tips" class="inner-toolbar">
        <div class="toolbar-btns">
          <div class="conditions">
            <el-radio-group v-model="advancedForm2.type" size="medium">
              <el-radio-button label="0">按日汇总</el-radio-button>
              <el-radio-button label="1">按月汇总</el-radio-button>
            </el-radio-group>
          </div>
          <div class="conditions">
            <span v-if="advancedForm2.type==='0'">
              <span :class="active2==='week'?'active':''" @click="handleTime2(7)">近7天</span>
              <span :class="active2==='mouth'?'active':''" @click="handleTime2(30)">近30天</span>
            </span>
            <span v-if="advancedForm2.type==='1'">
              <span :class="active2==='3mouth'?'active':''" @click="handleTime2(90)">近3月</span>
              <span :class="active2==='year'?'active':''" @click="handleTime2(365)">近12月</span>
            </span>
            <el-date-picker
              :default-time="['00:00:00', '23:59:59']"
              value-format="timestamp"
              style="width: 320px;margin-left: 10px;"
              v-model="advancedForm2.times"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="{disabledDate(time) { return time.getTime() > Date.now() }}"
              @change="timeChange2"
            ></el-date-picker>
          </div>
        </div>
      </div>
      <!-- 表格数据 -->
      <template slot="table-columns">
        <el-table-column prop="create_time" label="日期"/>
        <el-table-column label="收入">
          <template slot-scope="scope">{{ scope.row.income | unitPrice("￥") }}</template>
        </el-table-column>
        <el-table-column label="支出">
          <template slot-scope="scope">{{ scope.row.expenditure | unitPrice("￥") }}</template>
        </el-table-column>
        <el-table-column label="净收入">
          <template slot-scope="scope">{{ scope.row.revenue | unitPrice("￥") }}</template>
        </el-table-column>
        <el-table-column label="其中，退款支出">
          <template slot-scope="scope">{{ scope.row.refund_price_num | unitPrice("￥") }}</template>
        </el-table-column>
        <el-table-column label="其中，佣金支出">
          <template slot-scope="scope">{{ scope.row.shop_commission | unitPrice("￥") }}</template>
        </el-table-column>
        <el-table-column label="其中，交易手续费支出">
          <template slot-scope="scope">{{ scope.row.fee_money | unitPrice("￥") }}</template>
        </el-table-column>
        <el-table-column label="其他费用支出">
          <template slot-scope="scope">{{ scope.row.other_fee_money | unitPrice("￥") }}</template>
        </el-table-column>
      </template>
      <!-- 表格分页 -->
      <el-pagination
        v-if="tableData"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>
  </div>
</template>

<script>
import * as API_Account from "@/api/account";
import * as API_Home from "@/api/home";
import * as API_Statistics from "@/api/salesStatistics";
// import { RegExp } from "@libs/common/utils";

export default {
  name: "shopMoney",

  data() {
    return {
      tableHeight: document.body.clientHeight / 2,
      /** 列表loading状态 */
      loading: false,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 10,
      },

      /** 左侧数据参数 */
      orderLeftForm: {
        start: "",
        end: "",
        order_status: "",
        cycle_type: "TIME"
      },

      /** 列表数据 */
      tableData: "",

      /** 高级搜索数据 */
      advancedForm1: {
        times: "",
      },
      times: '',
      active: "day",
      activeName: "今日",
      /** 高级搜索数据 */
      advancedForm2: {
        type: "0", // 0 日汇总 1 月汇总
        times: "",
      },

      homeData: {},
      // 图表左侧数据
      orderLeftList: {},

      active2: "week",
    };
  },
  watch: {
    "advancedForm2.type": {
      handler() {
        const {active2} = this;
        if (this.advancedForm2.type === "0") {
          this.active2 = "week";
          this.handleTime2(7);
        } else {
          this.active2 = "3mouth";
          this.handleTime2(90);
        }
      },
      deep: true,
    }
  },
  mounted() {
    this.getHome_data();
    // window.onresize = this.countTableHeight;
    this.$nextTick(() => {
      this.sesalChart = this.$echarts.init(
        document.getElementById("echarts_right")
      );
    });
    // 获取今天的图表数据
    this.handleTime(0);
    // 获取近7天的的账户汇总
    this.handleTime2(7);
  },
  beforeRouteUpdate(to, from, next) {
    next();
  },
  methods: {
    getHome_data() {
      API_Home.getHome_date().then((res) => {
        this.homeData = res;
      });
    },
    // /** 窗口缩放时计算table高度 */
    // countTableHeight() {
    //   this.tableHeight = document.body.clientHeight / 2;
    //   /** 图表刷新 */
    //   setTimeout(this.sesalChart.resize);
    // },
    handleTime2(has) {
      // const today = new Date()
      // let start = ''
      // let end = ''
      // end = (today.getTime() - today.getHours() * 60 * 60 * 1000 - today.getMinutes() * 60 * 1000 - today.getSeconds() * 1000 - today.getMilliseconds()) / 1000
      // start = end - (has - 1) * 86400
      // end = end + 86399
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * (has - 1));
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      end.setHours(23);
      end.setMinutes(59);
      end.setSeconds(59);
      if (has === 7) {
        this.active2 = "week";
      } else if (has === 30) {
        this.active2 = "mouth";
      } else if (has === 90) {
        this.active2 = "3mouth";
      } else if (has === 365) {
        this.active2 = "year";
      }
      this.advancedForm2.times = [start, end];
      this.params.page_no = 1;
      this.GET_List();
    },
    handleTime(has) {
      const end = new Date();
      const start = new Date();
      start.setTime(start.getTime() - 3600 * 1000 * 24 * (has - 1));
      start.setHours(0);
      start.setMinutes(0);
      start.setSeconds(0);
      end.setHours(0);
      end.setMinutes(0);
      end.setSeconds(0);
      if (has === 0) {
        // 今天
        start.setTime(end.getTime());
        end.setTime(end.getTime());
        this.active = "day";
        this.activeName = "今日";
      } else if (has === 7) {
        // 周
        this.active = "week";
        this.activeName = "近7天";
      } else if (has === 30) {
        // 月
        this.active = "mouth";
        this.activeName = "近30天";
      } else if (has === 365) {
        // 年
        this.active = "year";
        this.activeName = "近1年";
      }
      this.advancedForm1.times = [start, end];
      this.orderLeftForm.start = parseInt(start.getTime() / 1000)
      this.orderLeftForm.end = parseInt(end.getTime() / 1000) + 86399
      // console.log(parseInt(start.getTime() / 1000));
      // console.log(parseInt(end.getTime() / 1000) - 1);
      this.get_chartsData();
      this.dashboardOrderLeft()
    },
    /** 订单统计左侧数据 */
    dashboardOrderLeft() {
      API_Home.dashboardOrderLeft(this.orderLeftForm).then((res) => {
        this.orderLeftList = res;
        console.log(res);
      });
    },
    /** 订单统计右侧数据 */
    get_chartsData() {
      this.loading = true;
      const params = {
        cycle_type: "TIME",
        start: parseInt(this.advancedForm1.times[0].getTime() / 1000),
        end: parseInt(this.advancedForm1.times[1].getTime() / 1000) + 86399,
      };
      API_Statistics.getRevenueData(params).then((res) => {
        console.log(res, "res");
        this.loading = false;
        this.sesalChart.setOption({
          backgroundColor: "#fff", // 背景色
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: res.xAxis,
          },
          yAxis: {
            name: "订单金额/￥元",
            type: "value",
            axisLabel: {
              formatter: "{value}",
            },
          },
          legend: {
            orient: "vertical",
            x: "right",
            y: "top",
            data: [res.series[0].name, res.series[1].name],
          },
          tooltip: {
            trigger: "axis",
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          series: [
            {
              name: res.series[0].name,
              data: res.series[0].data,
              type: "line",
              smooth: true, // 让曲线变平滑的
              symbol: "none", // 去掉点
            },
            {
              name: res.series[1].name,
              data: res.series[1].data,
              type: "line",
              smooth: true, // 让曲线变平滑的
              symbol: "none", // 去掉点
            },
            {
              name: res.series[2].name,
              data: res.series[2].data,
              type: "line",
              smooth: true, // 让曲线变平滑的
              symbol: "none", // 去掉点
            },
          ],
        });
      });
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_List();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_List();
    },

    /** 获取列表 */
    GET_List() {
      this.loading = true;
      const {times, type} = this.advancedForm2;
      console.log(times);
      const params = {
        cycle_type: "TIME",
        start: parseInt(times[0] / 1000),
        end: parseInt(times[1] / 1000),
        ...this.params,
        flag_month: type, // 是否是月(1是0否)
      };
      Object.keys(params).forEach((key) => {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      });
      API_Statistics.getAccountData(params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    /** 时间选择改变 */
    timeChange(val) {
      if (val !== null) {
        const end = new Date();
        const start = new Date();
        start.setTime(val[0]);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        end.setTime(val[1]);
        end.setHours(0);
        end.setMinutes(0);
        end.setSeconds(0);
        this.advancedForm1.times = [start, end];
        this.orderLeftForm.start = parseInt(start.getTime() / 1000);
        this.orderLeftForm.end = parseInt(end.getTime() / 1000) + 86399;
        this.active = "";
        this.get_chartsData();
        this.dashboardOrderLeft();
      }
    },
    /** 时间选择改变 */
    timeChange2(val) {
      if (val !== null) {
        const end = new Date();
        const start = new Date();
        start.setTime(val[0]);
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);
        end.setTime(val[1]);
        end.setHours(0);
        end.setMinutes(0);
        end.setSeconds(0);
        this.advancedForm2.times = [start, end];
        this.params.page_no = 1;
        this.active2 = "";
        this.GET_List();
      }
    }
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.item-row {
  margin-bottom: 10px;

  .el-col {
    padding-left: 2px !important;
    padding-right: 2px !important;
  }
}

.item_list {
  background: #ffffff;
  border-radius: 12px;
  margin: 0 5px;
  height: 130px;
  position: relative;

  .topBox {
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .textBox {
      span {
        color: #212121;
        font-size: 28px;
        font-weight: 400;
      }

      div {
        color: #212121;
        font-size: 12px;
        font-weight: 400;
      }
    }

    img {
      width: 60px;
    }
  }

  .bottomBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: yellow;
    width: 100%;
    padding: 8px 20px;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 12px;

    span {
      color: #fff;
      font-size: 14px;
    }
  }
}

.inner-toolbar {
  .conditions {
    span {
      cursor: pointer;
      line-height: 20px;
      height: 24px;
      display: inline-table;
      margin: 0 5px;
      color: #212121;
      font-size: 14px;
    }

    span.active {
      color: #007f7f;
      border-bottom: 2px solid #007f7f;
    }
  }
}

.first-container {
  color: #444444 !important;
  font-size: 15px !important;
  height: 173px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .col:nth-child(1) {
    margin-left: 10px;
  }

  .col {
    background-color: #ffffff;
    border-radius: 12px;
    position: relative;
    height: 173px;
    padding-bottom: 0;
    padding-left: 0 !important;
    margin: 0 10px;

    .col-p1 {
      display: flex;
      flex-direction: column;
      margin: 36px 0 0 36px;

      span:nth-child(1) {
        color: #212121;
        font-size: 36px;
        font-weight: 400;
      }

      span:nth-child(2) {
        font-size: 16px;
        font-weight: bold;
        color: #434343;
      }
    }

    .col-p2 {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 48px;
      line-height: 48px;
      margin-bottom: 0;
      padding: 0 26px;
      border-radius: 0 0 12px 12px;
      background: linear-gradient(
          -90deg,
          rgba(51, 205, 189, 0.8),
          rgba(0, 127, 127, 0.8)
      );
      font-size: 18px;
      color: #ffffff;

      display: flex;
      justify-content: space-between;
    }
  }
}

.echart-container {
  height: 448px;
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 20px;

  .echart-title {
    height: 50px;
    font-size: 18px;
    color: #007f7f;
    padding-left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    p:nth-child(2) {
      color: #212121;
      font-size: 14px;
    }

    p span {
      cursor: pointer;
      line-height: 20px;
      height: 24px;
      display: inline-table;
      margin: 0 5px;
    }

    p span.active {
      color: #007f7f;
      border-bottom: 2px solid #007f7f;
    }
  }

  .echart-alltitle {
    display: flex;
    justify-content: space-around;
  }

  .echarts-left {
    height: 100px;
    width: 15%;
    background: rgba(51, 205, 189, 0.08);
    border-radius: 8px;
    margin-top: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    h2 {
      font-size: 30px;
      font-weight: 400;
      line-height: 0px;
      color: #212121;
    }

    p {
      font-size: 15px;
      line-height: 0px;
      font-weight: 500;
      margin-top: -10px;
      color: #656565;
    }
  }

  .echarts-right {
    width: 80%;
    height: 350px;
  }
}

.show-pwd {
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer;
}

.el-date-editor.el-input {
  width: 180px;
}

/deep/ .form-item-sex .el-form-item__content {
  width: 180px;
}

/deep/ .form-item-region .el-form-item__content {
  min-width: 180px;
}

/deep/ .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 30px;
}

div.toolbar-btns {
  display: contents;
}
</style>
